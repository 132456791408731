import React, { Component } from "react";
import { connect } from "react-redux";
import { getPositions, setKeywordsPosition } from "../actions/keywordsActions.js"
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import ColorLegend from "../components/ColorLegend.js"

import "./keywords.scss";
import { API_ROOT, darkTextBackground, lightTextBackground, kColors } from "../constants"

import PostingSkills from "../components/keywords/PostingSkills.js"

class Keywords extends Component {
	renderKeywords() {
		if (this.props.positionId) {
			return this.props.positionKeywords.map(function(keyword, index) {
				console.log("RENDERING KEYWORDS")
				return (
					<div 
						className={`list-keyword ${keyword.used ? "used-keyword" : "unused-keyword"}`}
					>
						{index + 1}. {keyword.label}
					</div>
				) 
			})
		} else {
			return <div id="keywords-placeholder">
				Select a position for top skills and industry keywords.
				Keywords will highlight as you type them in your doc.
			</div>
		}
	}

  handleChange = selectedOption => {
    this.props.setKeywordsPosition(selectedOption.value, selectedOption.label, false)
    setTimeout(() => {
	    if (selectedOption.value == "request-position") {
	    	alert("Thanks, check back tomorrow!")
	    }
    }, 250)
  };

	renderOptions = () => {
		return this.props.positions.map(position => {
			return {
				value: position,
				label: position.name				
			}
		})
	}

	filterOptions = (inputValue: string, searchOptions) => { //returns matching options
	  return searchOptions.filter(kw =>
	    kw.label.toLowerCase().includes(inputValue.toLowerCase())
	  );
	};

	promiseOptions = inputValue =>
	  new Promise(resolve => {
		  let configObj = {
		    method: "GET",
		    headers: {
		      "Content-Type": "application/json",
		      Accept: "application/json",
		      Authorization: localStorage.getItem("token")
		    }
		  }


		  let searchOptions = null	

		  let previousValue = inputValue

  		setTimeout(() => {
				const currentInputVal = document.getElementById("keywords-position-selector").children[1].children[0].children[0].children[0].children[0].value
				if (currentInputVal === previousValue) {
				  console.log("FETCHING")
				  fetch(API_ROOT + `/positions-search/${inputValue}`, configObj)
				    .then(resp => resp.json())
				    .then((data) => {
				      if (data.matches.length > 0) {
					      searchOptions = data.matches.map(item => {
					      	return {label: item.name, value: item.id, type: "position"}
					      })				      	
				      } else {
								searchOptions = [{label: `Request "${inputValue}"`, value: "request-position", type: "position"}]
				      }
				    }) 

			    setTimeout(() => {
			      resolve(this.filterOptions(inputValue, searchOptions));
			    }, 1000);	
				} else {
					searchOptions = []
				}
  		}, 250)	    
	});

	render() {
		//onClick={this.props.getPositions} <-- removed from div: id="keyword-select-container"
		const { industryName, darkMode } = this.props
		return (
			<div id="keywords-container">
	      <div id="keywords-sections-wrapper">
		      <div id="position-keywords-wrapper">
			      <div id="keyword-select-container" >
				      <AsyncSelect
				      	id="keywords-position-selector"
				      	key={`my_unique_select_key__${this.props.positionId}`}
				      	defaultInputValue={this.props.positionName}
				      	defaultValue={"FS"}
				        onChange={this.handleChange}
				        loadOptions={this.promiseOptions}
				        placeholder="Position"
				      />
			      </div>			
						<div 
							id="keywords-list" className={this.props.positionId ? "keywords-present" : null}
							style={{
								color: darkMode ? "white" : "black",
								"background-color": darkMode ? darkTextBackground : lightTextBackground,
							  border: `2px solid ${kColors.positionKeywordColor}`

							}}
						>
							{this.renderKeywords()}	      	
						</div>
		      </div>
	      	<div id="industry-indicator" style={{color: kColors.industryKeywordColor}}>
		      	<div className="industry-divider" style={{border: `2px solid ${kColors.industryKeywordColor}`}}></div>
		      	<div id="industry-label">{industryName ? industryName.toUpperCase() : "Industry"}</div>
		      	<div className="industry-divider" style={{border: `2px solid ${kColors.industryKeywordColor}`}}></div>
    			</div>
	      	<PostingSkills darkMode={darkMode} />
	      </div>
	      {/*<ColorLegend darkMode={darkMode}/>*/}
			</div>				
		)
	}
}

const mapStateToProps = state => {
	return {
		positions: state.positions.positions,
		positionId: state.positions.keywordsPositionId,
		positionName: state.positions.keywordsPositionName,
		positionKeywords: state.positions.positionKeywords,
		industryName: state.positions.keywordsPositionIndustry 
	}
}

export default connect(mapStateToProps, { getPositions, setKeywordsPosition })(Keywords);