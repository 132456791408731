import React, { Component } from "react";
import { kColors, darkTextBackground, lightTextBackground } from "../../constants";
import { connect } from "react-redux"

// import "./contact.scss"

class CompanyScoreBar extends Component {
	componentDidUpdate(prevProps) {
			setTimeout(() => {document.getElementById("company-score-bar-wrapper").style.borderRight = `5px solid ${(this.props.companyValues.filter(kw => kw.used).length / this.props.companyValues.length) > 0.5 ? kColors.valueKeywordColor : this.props.darkMode ? darkTextBackground : lightTextBackground}`}, 200)		
	}	

	render() {
		const { darkMode } = this.props
		const { companyValues } = this.props
		const scoreBarHeight = () => {
			if (companyValues.length > 0) {
				return companyValues.filter(kw => kw.used).length / companyValues.length * 95
			} else {
				return 2
			}
		}
		return (
			<React.Fragment>
				<div id="company-score-bar-wrapper" style={{
						"background-color": darkMode ? darkTextBackground : lightTextBackground
					}}
				>
					<div id="company-score-bar-section">
						<div 
							id="company-score-bar" 
							style={{
								border: `2px solid ${kColors.valueKeywordColor}`,
								height: `${true ? scoreBarHeight() : 100}%`
							}}												
						>
						</div>
						<div 
							id="company-score-fraction"
							style={{color: kColors.valueKeywordColor}}
						>
							{companyValues.length > 0 ? `${companyValues.filter(kw => kw.used).length}/${companyValues.length}` : "0/0"}
						</div>

						<div
							id="company-halfway-mark-container"
							style={{
								"border-color": (companyValues.filter(kw => kw.used).length / companyValues.length) > 0.5 ? kColors.valueKeywordColor : "lightgrey",
								opacity: (companyValues.filter(kw => kw.used).length / companyValues.length) > 0.5 ? 1 : 0.5,							
							}}
						>
						</div>					
					</div>
				</div>
				{/*<div id="posting-connector-box"></div>*/}
			</React.Fragment>	
		)
	}
}

const mapStateToProps = state => {
	return {
		companyValues: state.companies.companyValues
	}
}

export default connect(mapStateToProps)(CompanyScoreBar);
