import React, { Component } from "react";
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import "./navigation.css"

import { logOut } from "../actions/usersActions.js"
import { API_ROOT, LINKEDIN_REDIRECT_DOMAIN } from "../constants"

import jwt_decode from "jwt-decode";

class NavBar extends Component {
	handleLogOut = () => {
		let token = localStorage.getItem("token")
		let decoded = jwt_decode(token)
		debugger
		this.props.logOut();
		localStorage.removeItem("token")
	}

	handleLinkedinLogin = () => {
		window.open(`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77cinu2cc8o64l&redirect_uri=${LINKEDIN_REDIRECT_DOMAIN}&state=fooobar&scope=r_liteprofile%20r_emailaddress`, "_self")

    // let configObj = {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //     Authorization: localStorage.getItem("token")
    //   }
    // }
    // // debugger
    // fetch(API_ROOT + `/linkedin-login`, configObj)
    // fetch(`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77cinu2cc8o64l&redirect_uri=http%3A%2F%2Flocalhost:3000%2Fauth%2Flinkedin%2Fcallback&state=fooobar&scope=r_liteprofile%20r_emailaddress%20w_member_social`)
    //   .then(resp => resp.json())
    //   .then((data) => {
    //     debugger 
    //     window.location = data.url    
    //   })		
	}


  handleTest = () => {
    let configObj = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.getItem("token")
      }
    }
    // debugger
    fetch(API_ROOT + `/test`, configObj)
      .then(resp => resp.json())
      .then((data) => {
        debugger
      })        
  }

	render() {
		const { userFirstName } = this.props
		const linkedin_auth_link = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77cinu2cc8o64l&redirect_uri=${LINKEDIN_REDIRECT_DOMAIN}&state=fooobar&scope=r_liteprofile%20r_emailaddress`
		return (
			<div id="nav-bar-wrapper">
				<div id="navbar-logo">Coverly</div>
				{/*<div id="navbar-account">{userFirstName ? userFirstName : "Sign In"}</div>*/}
        
        {/*<div onClick={this.handleTest}>TEST (blurb import)</div>*/}
				
				{/*<div id="profile-options">
					{ this.props.userFirstName 
						? 
							<div className="dropdown">
								<div className="nav-link user-dropdown">{this.props.userFirstName}</div>
								<div className="dropdown-content">
									<Link to="/"><div className="nav-link dropdown-item" onClick={this.handleLogOut}>Logout</div></Link>
								</div>
							</div>
						: 
						<div>
							<button onClick={this.handleLinkedinLogin} style={{"background-color":"blue", "color": "white"}}>Sign in/up with LinkedIn</button>							
						</div>
					} 										
				</div>*/}				
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		userFirstName: state.users.firstName
	}
}

export default connect(mapStateToProps, { logOut })(NavBar);

