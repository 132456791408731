import React, { Component } from "react";
import { connect } from "react-redux"
import Select from 'react-select';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import $ from "jquery"

import { saveNewCoverLetter, toggleCoverLetterSelected, getMatches, updateCoverLetterId, deleteCoverLetter } from "../actions/coverLetterActions.js"
import { setCompany, setKeywordsPosition, useKeyword, useIndustryKeyword, useSkills, getGeneralKeywords, useGeneralKeyword, useCompanyValue, useCompanyName } from "../actions/keywordsActions.js"

import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";
import "./editor.scss"

import FileBrowser from "../containers/FileBrowser.js"
import Selector from "./select/Selector.js"
import MenuCompanySelector from "./select/MenuCompanySelector.js"
import MenuPositionSelector from "./select/MenuPositionSelector.js"
import ReactTooltip from 'react-tooltip';
import PostingScoreBar from "./keywords/PostingScoreBar.js"
import CompanyScoreBar from "./culture-cam/CompanyScoreBar.js"

import SaveIcon from "./save-icon.svg"
import TrashIcon from "./trash-icon.svg"
import DownloadIcon from "./download-icon.svg"
import FeedbackButton from "../components/feedback/FeedbackButton.js"
import FolderIcon from "./folder-icon.svg"

import { API_ROOT, darkTextBackground, lightTextBackground, kColors } from "../constants"

class Editor extends Component {
  state = {
  	name: "",
  	body: "",
  	company: "",
  	position: "", 
  	newLetterId: null, 	
  	lastSavedAt: "",
  	showFileBrowser: false,
  	positionId: "",
  	keywords: [], //for test, eventually will be pulled from api in redux
  	keywordCounter: 0,
  	companyNameUsed: false,
  	popupOpen: false
  }

  componentDidMount() {
		const name = document.getElementById('name');
		name.addEventListener('input', (e) => {
	    this.handleNameChange(e)
		});

		this.props.getGeneralKeywords()  	

		// let docHeight = document.body.clientHeight;
		// let childHeight = docHeight; //2px due to borders
		// console.log(childHeight)
		// document.getElementById('editor-content-wrapper').style.height = childHeight;

		// console.log("WELL LOOKIE HERE")
		// document.getElementById('body-pretty').style.height = document.getElementById('body').style.height

		// Event listener for the 'beforeunload' event
		window.addEventListener('beforeunload', function (e) {
				let bodyText = document.getElementById("body").innerText
		    // Check if any of the input fields are filled
		    if (bodyText) {
		        // Cancel the event and show alert that
		        // the unsaved changes would be lost
		        e.preventDefault();
		        e.returnValue = '';
		    }
		});

		const body = document.getElementById('body');
		body.addEventListener('input', (e) => {
		    this.handleBodyChange(e)
		});  			

		// sanitize pasted text
		body.addEventListener("paste", function(e) {
		    // cancel paste
		    e.preventDefault();
		    // get text representation of clipboard
		    let text = (e.originalEvent || e).clipboardData.getData('text/plain');
		    // insert text manually	
				if (text.includes("\n")) {
					let bodyRawSectionArray = text.split("\n")
					let divvedArray = bodyRawSectionArray.map(section => {
					const trimmedSection = section.trim()
					if (trimmedSection === "") {
							return "<div><br></div>"
						} else {
							return `<div>${section}</div>`
						}
					})

					text = divvedArray.join("")
				}
		    document.execCommand("insertHTML", false, text);
		});

		document.getElementById('body').focus();
  }

  componentDidUpdate(prevProps) {
  	// rehighlights keywords if position selection changes, either in menu bar or keywords bar
  	if (prevProps.keywordsPositionId != this.props.keywordsPositionId
  			|| prevProps.companyName.name != this.props.companyName.name) {
  		this.prettifyBody()	
  	}

  	if (prevProps.positionName != this.props.positionName 
  			|| prevProps.postingIdentifier != this.props.postingIdentifier 
  			|| prevProps.coverLetterId != this.props.coverLetterId
  			) {  // && !!prevProps.selectedKeywordsPosition
  			// debugger
 			this.prettifyBody()		
  	}

  	if (document.getElementById('body-pretty').style.height != document.getElementById('body').style.height) {
  		// document.getElementById('body-pretty').style.height = document.getElementById('body').style.height
  	}
  }

  handleNameChange = (e) => {
  	this.setState({name: e.target.value})
  }

  handleBodyChange = (e) => {
  	// debugger
  	// console.log(this.state.body)
  	let prevText = e.target.innerText;
  	this.setState({
  		[e.target.id]: e.target.innerText
  	}, () => {
 	  	
			const body = document.getElementById("body");
 	  	// if (body.innerHTML.includes("&nbsp;")) {
 	  	// 	body.innerHTML = body.innerHTML.replace("&nbsp;", "\n\n")
 	  	// }
 	  	// const caret = this.getCaretCharacterOffsetWithin(body);
 	  	// this.highlightKeywords("change");
  		setTimeout(() => {
				if (e.target.id === "body" && e.target.innerText === prevText) {
					return this.props.coverLetterId ? null  : null //this.saveCoverLetter(this.state) : null
				}
  		}, 500)
  	})
		this.prettifyBody()
  }

	prettifyBody = () => {
		// debugger
		const bodyPretty = document.getElementById("body-pretty");
		const bodyRaw = document.getElementById("body");
		const keywords = this.props.positionKeywords ? this.props.positionKeywords : this.state.keywords
		const industryKeywords = this.props.industryKeywords ? this.props.industryKeywords : this.state.keywords
		const generalKeywords = this.props.generalKeywords
		const companyValues = this.props.companyValues
		const companyName = this.props.companyName

  	// if (bodyRaw.innerHTML.includes("&nbsp;")) {
  	// 	bodyRaw.innerHTML.replace("&nbsp;", "BZZZZ")
  	// }

		// Sometimes, if a cover letter was written outside of the Coverly editor, it's innerHTML will be
		// formatted diffeerently than if it was written in Coverly. Specifically, line breaks will be delimited
		// by the '\n' character, rather than by <div> elements. This piece of code converts '\n' line breaks into
		// <div> elements so that bodyPretty correctly lines up with bodyRaw.
		let bodyRawInnerHTML = bodyRaw.innerHTML			
		if (bodyRawInnerHTML.includes("\n")) {
			let bodyRawSectionArray = bodyRaw.innerHTML.split("\n")
			let divvedArray = bodyRawSectionArray.map(section => {
			const trimmedSection = section.trim()
			if (trimmedSection === "") {

				// if (/\S/.test(section)) { // in other words, if there's only whitespace
					// console.log("spacetime")
					return "<div><br></div>"
				} else {
					// console.log("sectional")
					return `<div>${section}</div>`
				}
			})

			bodyRawInnerHTML = divvedArray.join("")
		}

		// This part wraps each keyword match in bodyRawInnerHTML in a span, and also relays whether
		// a keyword is currently used or not to the positionsReducer, which then feeds to the Keywords component.	
		// NOTE: regexes are currently not being used. Will have to circle back on that	
		let generalKeywordUsageArray = []
		generalKeywords.filter(kw => kw.label != "C++").map(keyword => {
			let keywordRegex = null
			if (keyword.label.split(" ").length === 1 && keyword.label != "C++") {
				keywordRegex = new RegExp("\\b" + keyword.label + "\\b")
			} else {
				// keywordRegex = new RegExp(keyword.label)
				keywordRegex = keyword.label
			}
			
			// if (bodyRawInnerHTML.includes(keyword.label)) {
			if (bodyRawInnerHTML.match(keyword.label) || (bodyRawInnerHTML.match(keyword.label.toLowerCase()))) {
				if (!keyword.used) {
					document.getElementById("body-pretty").style.borderColor = kColors.generalKeywordColor
					setTimeout(() => {document.getElementById("body-pretty").style.borderColor = "transparent"}, 200)					
				}

				if (bodyRawInnerHTML.match(keywordRegex)) {
					bodyRawInnerHTML = bodyRawInnerHTML.replace(keyword.label, `<span class="highlighted-general-keyword highlighted-keyword" style="color: ${kColors.generalKeywordColor}">${keyword.label}</span>`)
					// this.props.useKeyword(keyword.label, true)
					generalKeywordUsageArray.push({label: keyword.label, used: true, type: "general-keyword", industry: keyword.industry_id, companies: keyword.companies})
				} 
				else if (bodyRawInnerHTML.match(keyword.label.toLowerCase()) && keyword.label.toLowerCase() != "c" && keyword.label.toLowerCase() != "r") {
						bodyRawInnerHTML = bodyRawInnerHTML.replace(keyword.label.toLowerCase(), `<span class="highlighted-general-keyword highlighted-keyword" style="color: ${kColors.generalKeywordColor}">${keyword.label.toLowerCase()}</span>`)
						// this.props.useKeyword(keyword.label, true)
						generalKeywordUsageArray.push({label: keyword.label, used: true, type: "general-keyword", industry: keyword.industry_id, companies: keyword.companies})					
				}
			} else {
				generalKeywordUsageArray.push({label: keyword.label, used: false, type: "general-keyword", industry: keyword.industry_id, companies: keyword.companies})
			}
		})

		let industryKeywordUsageArray = []
		industryKeywords.filter(kw => kw.label != "C++").map(keyword => {
			let keywordRegex = null
			if (keyword.label.split(" ").length === 1 && keyword.label != "C++") {
				keywordRegex = new RegExp("\\b" + keyword.label + "\\b")
			} else {
				// keywordRegex = new RegExp(keyword.label)
				keywordRegex = keyword.label
			}
			
			// if (bodyRawInnerHTML.includes(keyword.label)) {
			if (bodyRawInnerHTML.match(keyword.label) || (bodyRawInnerHTML.match(keyword.label.toLowerCase()))) {
				if (!keyword.used) {
					document.getElementById("body-pretty").style.borderColor = kColors.industryKeywordColor
					setTimeout(() => {document.getElementById("body-pretty").style.borderColor = "transparent"}, 200)					
				}

				if (bodyRawInnerHTML.match(keyword.label)) {
					bodyRawInnerHTML = bodyRawInnerHTML.replace(keyword.label, `<span class="highlighted-industry-keyword highlighted-keyword" style="color: ${kColors.industryKeywordColor}">${keyword.label}</span>`)
					// this.props.useKeyword(keyword.label, true)
					industryKeywordUsageArray.push({label: keyword.label, used: true})
				} else {
					industryKeywordUsageArray.push({label: keyword.label, used: false})	
				}
				// else if (bodyRawInnerHTML.match(keyword.label.toLowerCase()) && keyword.label.toLowerCase() != "c" && keyword.label.toLowerCase() != "r") {
				// 		bodyRawInnerHTML = bodyRawInnerHTML.replace(keyword.label.toLowerCase(), `<span class="highlighted-industry-keyword highlighted-keyword" style="color: ${kColors.industryKeywordColor}">${keyword.label.toLowerCase()}</span>`)
				// 		// this.props.useKeyword(keyword.label, true)
				// 		industryKeywordUsageArray.push({label: keyword.label, used: true})					
				// }
			} else {
				industryKeywordUsageArray.push({label: keyword.label, used: false})
			}
		})


		//this part if for position keywords
		let keywordUsageArray = []
		keywords.filter(kw => kw.label != "C++").map(keyword => {
			let keywordRegex = null
			if (keyword.label.split(" ").length === 1 && keyword.label != "C++") {
				keywordRegex = new RegExp("\\b" + keyword.label + "\\b")
			} else {
				// keywordRegex = new RegExp(keyword.label)
				keywordRegex = ` ${keyword.label}`
			}
			
			// if (bodyRawInnerHTML.includes(keyword.label)) {
			if (bodyRawInnerHTML.match(keyword.label) || (bodyRawInnerHTML.match(keyword.label.toLowerCase()))) {
				if (!keyword.used) {
					document.getElementById("body-pretty").style.borderColor = kColors.positionKeywordColor
					setTimeout(() => {document.getElementById("body-pretty").style.borderColor = "transparent"}, 200)					
				}

				if (bodyRawInnerHTML.match(keyword.label)) {
					bodyRawInnerHTML = bodyRawInnerHTML.replace(keyword.label, `<span class="highlighted-position-keyword highlighted-keyword" style="color: ${kColors.positionKeywordColor}">${keyword.label}</span>`)
					// this.props.useKeyword(keyword.label, true)
					keywordUsageArray.push({label: keyword.label, used: true})
				} 
				else if (bodyRawInnerHTML.match(keyword.label.toLowerCase()) && keyword.label.toLowerCase().length > 1 && this.props.keywordsPositionIndustry != "Tech") {
						bodyRawInnerHTML = bodyRawInnerHTML.replace(keyword.label.toLowerCase(), `<span class="highlighted-position-keyword highlighted-keyword" style="color: ${kColors.positionKeywordColor}">${keyword.label.toLowerCase()}</span>`)
						// this.props.useKeyword(keyword.label, true)
						keywordUsageArray.push({label: keyword.label, used: true})					
				} else {
					keywordUsageArray.push({label: keyword.label, used: false})	
				}
			} else {
				keywordUsageArray.push({label: keyword.label, used: false})
			}
		})

		let valuesUsageArray = []
		this.props.companyValues.map(skill => {
			let skillRegex = null
			if (skill.label.split(" ").length === 1) {
				skillRegex = new RegExp("\\b" + skill.label + "\\b")
			} else {
				skillRegex = new RegExp(skill.label)
			}

			//creates highlight span for posting skill match, regardless of alphabet case
			if (bodyRawInnerHTML.includes(skill.label) || bodyRawInnerHTML.includes(skill.label.toLowerCase())) {
				if (!skill.used) {
					document.getElementById("body-pretty").style.borderColor = kColors.valueKeywordColor
					setTimeout(() => {document.getElementById("body-pretty").style.borderColor = "transparent"}, 200)					
				}

				if (bodyRawInnerHTML.includes(skill.label)) {
					bodyRawInnerHTML = bodyRawInnerHTML.replace(skill.label, `<span class="highlighted-value highlighted-keyword" style="color: ${kColors.valueKeywordColor}">${skill.label}</span>`)
					// this.props.useKeyword(keyword.label, true)
					valuesUsageArray.push({label: skill.label, used: true, type: skill.type == "company-value" ? "company-value" : "company-culture"})					
				} else if (bodyRawInnerHTML.includes(skill.label.toLowerCase())) {
						bodyRawInnerHTML = bodyRawInnerHTML.replace(skill.label.toLowerCase(), `<span class="highlighted-value highlighted-keyword" style="color: ${kColors.valueKeywordColor}">${skill.label.toLowerCase()}</span>`)
						// this.props.useKeyword(keyword.label, true)
						valuesUsageArray.push({label: skill.label, used: true, type: skill.type == "company-value" ? "company-value" : "company-culture"})					
				} else {
					valuesUsageArray.push({label: skill.label, used: false})	
				}
			} else {
				valuesUsageArray.push({label: skill.label, used: false, type: skill.type == "company-value" ? "company-value" : "company-culture"})
			}
		})

		//this is for job posting keyword matches
		let skillsUsageArray = []
		this.props.postingSkills.map(skill => {
			let skillRegex = null
			if (skill.label.split(" ").length === 1) {
				skillRegex = new RegExp("\\b" + skill.label + "\\b")
			} else {
				skillRegex = new RegExp(skill.label)
			}
			//creates highlight span for posting skill match, regardless of alphabet case
			if (bodyRawInnerHTML.includes(skill.label) || bodyRawInnerHTML.includes(skill.label.toLowerCase())) {
				if (!skill.used) {
					document.getElementById("body-pretty").style.borderColor = kColors.postingKeywordColor
					setTimeout(() => {document.getElementById("body-pretty").style.borderColor = "transparent"}, 200)					
				}

				if (bodyRawInnerHTML.includes(skill.label)) {
					bodyRawInnerHTML = bodyRawInnerHTML.replace(skill.label, `<span class="highlighted-skill highlighted-keyword" style="color: ${kColors.postingKeywordColor}">${skill.label}</span>`)
					// this.props.useKeyword(keyword.label, true)
					skillsUsageArray.push({label: skill.label, used: true})					
				} else if (bodyRawInnerHTML.includes(skill.label.toLowerCase() && skill.label.toLowerCase().length > 4)) {
						bodyRawInnerHTML = bodyRawInnerHTML.replace(skill.label.toLowerCase(), `<span class="highlighted-skill highlighted-keyword" style="color: ${kColors.postingKeywordColor}">${skill.label.toLowerCase()}</span>`)
						// this.props.useKeyword(keyword.label, true)
						skillsUsageArray.push({label: skill.label, used: true})					
				} else {
					skillsUsageArray.push({label: skill.label, used: false})	
				}
			} else {
				skillsUsageArray.push({label: skill.label, used: false})
			}
		})

		let companyNameObj = {name: companyName.name, used: false, type: "company-name"}
		if (bodyRawInnerHTML.includes(companyName.name)) {
			if (!companyName.used) {
				document.getElementById("body-pretty").style.borderColor = kColors.companyKeywordColor
				setTimeout(() => {document.getElementById("body-pretty").style.borderColor = "transparent"}, 200)
			}
			bodyRawInnerHTML = bodyRawInnerHTML.replace(companyName.name, `<span class="highlighted-company-name highlighted-keyword" style="color: ${kColors.companyKeywordColor}">${companyName.name}</span>`)
			companyNameObj = {name: companyName.name, used: true, type: "company-name"}
		} else {
			companyNameObj = {name: companyName.name, used: false, type: "company-name"}
		}

		this.props.useCompanyValue(valuesUsageArray)
		this.props.useGeneralKeyword(generalKeywordUsageArray)
		this.props.useIndustryKeyword(industryKeywordUsageArray)
		this.props.useKeyword(keywordUsageArray)
		this.props.useSkills(skillsUsageArray)
		this.props.useCompanyName(companyNameObj)

		return bodyPretty.innerHTML = bodyRawInnerHTML //`${prettyContent}`
	}


  handleLetterSelect = () => {
		this.props.toggleCoverLetterSelected()  	
  	setTimeout(() => {
	  	this.setState({
	  		name: this.props.selectedCoverLetter.name,
	  		body: this.props.selectedCoverLetter.body,
	  		company: this.props.selectedCoverLetter.company,
	  		position: this.props.selectedCoverLetter.position
	  	}, () => {
	  		if (!this.props.coverLetterselected) {
		  		// this.highlightKeywords("load");
		  		this.divifyBody()
		  		this.prettifyBody();
	  		}
	  	})
  	}, 500)
  }

  divifyBody = () => {
  	const bodyRaw = document.getElementById("body");
		let bodyRawInnerHTML = bodyRaw.innerHTML		

		if (bodyRawInnerHTML.includes("\n")) {
			let bodyRawSectionArray = bodyRaw.innerHTML.split("\n")
			let divvedArray = bodyRawSectionArray.map(section => {
			const trimmedSection = section.trim()
			if (trimmedSection === "") {
				// if (/\S/.test(section)) { // in other words, if there's only whitespace
					// console.log("spacetime")
					return "<div><br></div>"
				} else {
					// console.log("sectional")
					return `<div>${section}</div>`
				}
			})

			bodyRawInnerHTML = divvedArray.join("")
		}
		bodyRaw.innerHTML = bodyRawInnerHTML
  }

  saveCoverLetter = (state, replace = false, newVersion = false) => {	
	  // this.props.saveCoverLetter(this.props.coverLetterId, state.name, state.body, this.props.coverLetters)

	  const bodyRaw = document.getElementById("body");
	  let bodyHTML = bodyRaw.innerHTML
	  // let bodyHTMLSplit = bodyHTML.split("<div><br></div>")
	  let bodyHTMLSplit = bodyHTML.split("</div><div>")
	  bodyHTMLSplit[0] = bodyHTMLSplit[0].split("<div>")
	  bodyHTMLSplit = bodyHTMLSplit.flat()

	  // bodyHTMLSplit.map((section, index) => {
	  // 	if (section.includes("<div></div>")) {
	  // 		bodyHTMLSplit[index] = section.split("<div></div>")
	  // 	}
	  // })
		let strippedBodyStringArray = bodyRaw.innerText.split("\n").filter(item => item) //removing all line breaks from string array
		let newBodyStringArray = []

		let trimmedArrayPositioner = 0
		for (let i in bodyHTMLSplit) {
	  	i = parseInt(i)
	  	if (bodyHTMLSplit[i] != "<br>") {
	  		// let doThing1 = true
	  		// let y = 1
	  		// while (!!doThing1) {
	  		// 	if (bodyHTMLSplit[i - y] === "") {
	  		// 		++y
	  		// 	} else {
	  		// 		doThing1 = false
	  		// 	}
	  		// }

	  		// let stringIndex = i - y + 1

	  		let doThing2 = true
	  		let x = 1
	  		let lineBreaks = [] //this is how many line breaks one "<div><br></div>" is worth
	  		while (!!doThing2) {
		  		if (bodyHTMLSplit[i + x] === "<br>")  {
		  			lineBreaks.push("");
		  			++x
		  		} else {
		  			doThing2 = false
		  		}
	  		}

	  		let paragraphArray = [strippedBodyStringArray[trimmedArrayPositioner]]
	  		let newParagraphArray = paragraphArray.concat(lineBreaks)
	  		trimmedArrayPositioner++
	  		// debugger
	  		newBodyStringArray.push(newParagraphArray)
	  		// strippedBodyStringArray.splice(i + 1, 0, "")
	  	}
		}	  

		let bodyString = newBodyStringArray.flat().join("\n").trim();

	 //  let bodyString = state.body
		// let bodySplit = bodyString.split("\n")

		// for (let i in bodySplit) {
	 //  	if (i > 0 && bodySplit[i] != "" && bodySplit[i - 1] === "") {
		// 		console.log(i, bodySplit[i])	  		
	 //  		bodySplit.splice((i - 1), 1)
		// 		debugger
	 //  	}
		// }	  
		// debugger

		// bodyString = bodySplit.join("\n")
	



		// for (let i in bodyString) {
	 //  	if (i > 0 && bodyString[i] != "\n" && bodyString[i - 1] === "\n") {
	 //  		bodyString[i - 1] = "X"
	 //  		console.log("blorp")
	 //  	}
		//   console.log(bodyString[i]);	  	
		// }	  

	 	const id = this.props.coverLetterId ? this.props.coverLetterId : state.newLetterId
	  let configObj = {
	    method: "POST",
	    headers: {
	      "Content-Type": "application/json",
	      Accept: "application/json",
	      Authorization: localStorage.getItem("token")
	    },
      body: JSON.stringify({
        id: id,
        name: state.name,
        body: bodyString, 
        company: state.company,
        position: state.position,
        replace: replace,
        newVersion: newVersion
      })	    
	  }

	  if (state.name && state.body && state.company && state.position) {
		  fetch(API_ROOT + `/users/${this.props.userId}/cover_letters`, configObj)
		    .then(resp => resp.json())
		    .then((data) => {
					if (data.duplicate) {
						if (window.confirm(`You already have a cover letter named ${state.name}. Do you want to replace it?`)) {
							this.saveCoverLetter(state, true)
						} 				
					}	else if (data.new_version) {
						if (window.confirm(`Changing the name of an existing cover letter will create a new one.`)) {
							this.saveCoverLetter(state, false, true)
						} 							
					} else {
			    	// newLetterId is to identify the CL as existing but not reloading it through SelectedCoverLetter (to minimize potential editor errors)
			    	const coverLetterId = data.id
			    	this.setState({lastSavedAt: data.saved_at, newLetterId: coverLetterId})	
						this.props.updateCoverLetterId(coverLetterId)
					}			  

					// IF we decide to push new CLs to existing CL list (if we're not pulling fresh when we open file browser)
				  // if (!data.id) {
				  // 	this.props.saveNewCoverLetter(data)
				  // }
		   })
		    .catch(err => alert(err.message))		  	
	  
	  } else if (!state.name) {
	  	alert("cannot save without a name");
	  } else if (!state.body) {
	  	alert("cannot save without a body");
	  } else if (!state.company) {
	  	alert("cannot save without a company");
	  } else if (!state.position) {
	  	alert("cannot save without a position");
	  }
  }

  deleteCoverLetter = () => {
  	this.props.deleteCoverLetter(this.props.userId, this.props.coverLetterId);
  	this.setState({name: "", body: ""}, () => {
  		document.getElementById("body").innerText = "";
  	})
  }

  downloadCoverLetter = (state) => {
  	if (this.state.name && this.state.body) {
	  	const doc = new Document();
			const paragraph_array = state.body.split("\n")//.filter(i => i != false)
			const paragraphs = paragraph_array.map(par => new Paragraph(par))

			doc.addSection({
		    properties: {},
		    children: paragraphs,
			});
			
	    Packer.toBlob(doc).then(blob => {
	      console.log(blob);
	      saveAs(blob, `${this.props.selectedCoverLetter ? this.props.selectedCoverLetter.name : this.state.name}.docx`);
	      console.log("Document created successfully");
	    }); 	  		
  	} else {
  		alert("Please make sure your cover letter has a name and content before downloading")
  	}

  }

  toggleFileBrowser = (bool) => {
  	this.setState({showFileBrowser: bool})
		// debugger
		if (!bool) {
	  	this.handleLetterSelect()
		}
  	// if (document.getElementById("file-browser-wrapper")) {
  	// 	const browser = document.getElementById("file-browser-wrapper");
  	// 	browser.style.width = `${document.getElementById("name").clientWidth}px`
  	// }
  }

	allowDrop = e => {
		e.preventDefault();
	}

	drop = (e, state) => {
		e.preventDefault();
		console.log(JSON.parse(e.dataTransfer.getData("object")))
		let transferObj = JSON.parse(e.dataTransfer.getData("object"))

		this.setState({body: state.body + transferObj.text}, () => {

			this.saveCoverLetter(state);
		})
	}	

	handleMatches = (e, category) => {
		if (e.target.value) {
			this.props.getMatches(category, e.target.value)
		}
	}

	handleCompanySelect = company => {
		this.props.setCompany(company.value)
		this.setState({company: company.label})
	}

	handlePositionSelect = position => {
		this.props.setKeywordsPosition(position.value, true)
		this.setState({position: position.label})
	}

	showFileBrowser = () => {
		if (this.state.showFileBrowser) {
			return (
				<div style={{"display": "block"}} >
					<FileBrowser handleFileSelect={this.toggleFileBrowser} handleBlur={this.toggleFileBrowser}/>
				</div>	
			)		
		}
	}

	render() {
		// debugger
		const { company, position, popupOpen, body } = this.state
		const { darkMode, companyName } = this.props		
		return (
			<div id="editor-wrapper">
				<div></div>
				<div 
					id="editor-name-wrapper"
					style={{
						"background-color": darkMode ? darkTextBackground : lightTextBackground,
						color: darkMode ? "white" : "black",
						transition: "background-color 0.5s ease" 
					}}
				>
									
					{this.showFileBrowser()}
					<div 
						id="naming-options" 

					>
						<input 
							id="name"
							autocomplete="off"
							type="text" 
							placeholder="Title..."
							value = {this.state.name}
							style={{
								"background-color": darkMode ? darkTextBackground : lightTextBackground,
								color: darkMode ? "white" : "black",
								transition: "background-color 0.5s ease" 
							}}							

							// value={this.props.coverLetterSelected ? this.handleLetterSelect() : this.props.selectedCoverLetter ? this.props.selectedCoverLetter.name : null}
						/>
						
						{/*
						<div id="cl-tags-wrapper" style={{display: this.props.selectedCoverLetter ? "flex" : "flex"}}>
				      <div id="company-select-wrapper" className="tag-select-wrapper">
					      <MenuCompanySelector onCompanySelect={this.handleCompanySelect}/>	
				      </div>

				      <div id="position-select-wrapper" className="tag-select-wrapper">
					      <MenuPositionSelector onPositionSelect={this.handlePositionSelect}/>					      
				      </div>			      							
						</div>
						*/}
						<div id="editor-buttons-wrapper">
							{/*<div 
								style={{"background-color": "green", display: !this.props.selectedCoverLetter ? "flex" : "flex", 
									opacity: (this.props.coverLetterId || (this.state.name && this.state.body && this.state.company && this.state.position)) ? "1" : "0.5"}} 
								className="editor-button" data-tip="save" data-place="bottom" data-delay-show='500' 
								onClick={() => this.props.userId ? this.saveCoverLetter(this.state) : alert("please sign in to save cover letters")}><img src={SaveIcon} alt="save" width="20px" /></div>
							<div 
								style={{"background-color": "red", display: !this.props.selectedCoverLetter ? "flex" : "flex", opacity: this.props.coverLetterId ? "1" : "0.5"}} 
								className="editor-button" data-tip="delete" data-delay-show='500' data-place="bottom" 
								onClick={this.props.coverLetterId ? this.deleteCoverLetter : null}><img src={TrashIcon} alt="delete" width="20px"/>
							</div>*/}
							<div id="coverly-logo-editor">Coverly</div>	
							<FeedbackButton />
							<div 
								style={{"background-color": "teal"}} 
								className="editor-button" data-delay-show='500' data-tip="download" data-place="bottom" 
								onClick={() => this.downloadCoverLetter(this.state)}><img src={DownloadIcon} alt="download" width="20px"/></div>
							{/*<div 
								style={{"background-color": "GoldenRod", opacity: this.props.userId ? "1" : "0.5"}} 
								className="editor-button" data-delay-show='500' data-tip="file manager" data-place="bottom" 
								onClick={() => this.props.userId ? this.toggleFileBrowser(true) : alert("please sign in to access your files")}><img src={FolderIcon} alt="folder" width="20px"/>
							</div>*/}
							<ReactTooltip />
						</div>	
					</div>
				</div>
				
				{/*<div id="posting-connector-box"></div>*/}		
				<div id="content-editor-plus-csb-wrapper">
					<div 
						id="editor-content-wrapper"
					>
						{/*<div id="time-saved-marker">Last saved at: {this.state.lastSavedAt}</div>*/}
						<div 
							id="company-name-marker"
							style={{color: (companyName.name && (!body.includes(companyName.name)) && body) ? kColors.companyKeywordColor : "transparent" }}
						>
							missing company name
						</div>
						<div 
							id="body-pretty"
							style={{
								"background-color": darkMode ? darkTextBackground : lightTextBackground,
								// transition: "background-color 0.5s ease",
								color: darkMode ? "white" : "black",
								// transition: "color 0.5s ease",
							}}
						>
							{/*<div id="coverly-logo-editor">Coverly</div>*/}
							<div id="paste-prompt-box">
								<div id="paste-prompt" style={{color: darkMode ? "lightgrey" : "grey", display: this.state.body ? "none" : "block"}}>Type/Paste your Cover Letter to get started</div>
								{/*<div>------------- OR ---------------</div>*/}
							</div>
						</div>
						<div 
							id="body"
							style={{"caret-color": darkMode ? "white" : "black"}}
							contentEditable="true"
							onDragOver={this.allowDrop}
							onDrop={(e) => this.drop(e, this.state)}
							data-placeholder="" //Write or paste your cover letter here and watch important keywords highlight as you type. The more features you use, the more insightful the highlights.
						>
							{/*this.props.coverLetterSelected ? this.handleLetterSelect() : this.props.selectedCoverLetter ? this.props.selectedCoverLetter.body : null*/}
							{this.props.selectedCoverLetter ? this.props.selectedCoverLetter.body : null}
							{/*this.state.body*/}				
						</div>
						<PostingScoreBar darkMode={darkMode} body={!!this.state.body} />
						<div 
							id="example-button-box" 
							// onClick={() => alert("HEY")} 
							style={{display: this.state.body ? "none" : "block"}}
						>
							
							{<div id="example-button" onClick={() => this.setState({popupOpen: !popupOpen})} style={{color: darkMode ? darkTextBackground : lightTextBackground}} >Demo</div>}

				      <Popup open={popupOpen} closeOnDocumentClick onClose={() => this.setState({popupOpen: false})}>
				        <div className="modal">
									{/*<a className="close" onClick={() => this.setState({popupOpen: false})}>
				            &times;
				          </a>*/}
				          <div id="demo-popup-close" onClick={() => this.setState({popupOpen: false})}>✕</div>
				        	{/*<img src="https://www.looper.com/img/gallery/why-zoidberg-is-the-saddest-character-in-all-of-futurama/intro-1616079095.jpg" alt="" width="500px"></img>*/}
									{/*<div style={{width: "480px"}}><iframe allow="fullscreen" frameBorder="0" height="270" src="https://giphy.com/embed/4SQJGQSYoCh1KhK8AG/video" width="480"></iframe></div>*/}
									
									<iframe width="100%" height="100%" src="https://www.youtube.com/embed/YEmtAwzjwcw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

{/*{									<video id="video-player" controls autoPlay muted>
									  <source src="https://i.vimeocdn.com/video/1298219203-ae5931de69eefa8371146f7335aa1dc2e491c8126a3d5d3a5?mw=1100&mh=688&q=70" type="video/mp4"></source>
										Your browser does not support the video tag.
									</video>}*/}


{/*									<iframe 
										src='https://streamable.com/0kcvkn'
						        frameborder='0'
						        allow='autoplay; encrypted-media'
						        allowfullscreen
						        title='video'
						        id="video-player"
									/>*/}									
				        </div>
				      </Popup>
						</div>							
					</div>
					{<CompanyScoreBar darkMode={darkMode}/>}
				</div>		
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		userId: state.users.id,
		coverLetters: state.coverLetters.coverLetters,
		selectedCoverLetter: state.coverLetters.coverLetters.find(cl => cl.id === state.coverLetters.selectedCoverLetterId),
		coverLetterId: state.coverLetters.selectedCoverLetterId,
		coverLetterSelected: state.coverLetters.coverLetterSelected,
		companies: state.coverLetters.companies,
		positions: state.coverLetters.positions,
		editorPositionId: state.positions.editorPositionId,
		editorPositionName: state.positions.editorPositionName,
		keywordsPositionIndustry: state.positions.keywordsPositionIndustry,
		keywordsPositionId: state.positions.keywordsPositionId,
		positionKeywords: state.positions.positionKeywords,
		industryKeywords: state.positions.industryKeywords,
		postingSkills: state.positions.postingSkills,
		postingIdentifier: state.positions.postingIdentifier,

		generalKeywords: state.users.generalKeywords,
		companyValues: state.companies.companyValues,
		companyName: state.companies.companyName
	}
}

export default connect(mapStateToProps, { toggleCoverLetterSelected, getMatches, saveNewCoverLetter, updateCoverLetterId, setCompany, setKeywordsPosition, useKeyword, useIndustryKeyword, useSkills, deleteCoverLetter, getGeneralKeywords, useGeneralKeyword, useCompanyValue, useCompanyName })(Editor);



