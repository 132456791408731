import { combineReducers } from "redux"
import usersReducer from "./usersReducer.js"
import coverLettersReducer from "./coverLettersReducer.js"
import positionsReducer from "./positionsReducer.js"
import paymentsReducer from "./paymentsReducer.js"
import companiesReducer from "./companiesReducer.js"


export default combineReducers({
	users: usersReducer,
	coverLetters: coverLettersReducer,
	payments: paymentsReducer,
	companies: companiesReducer,
	positions: positionsReducer
})