export default function usersReducer(state = {
	coverLetters: [],
	selectedCoverLetterId: "",
	coverLetterSelected: false,
	companies: [],
	positions: []
}, action) {
	switch(action.type) {
		case "ADD_COVER_LETTERS":
			return {
				...state,
				coverLetters: action.coverLetters
			}

		case "LOAD_COVER_LETTER":	
			let loadedCl = state.coverLetters.find(cl => cl.id === action.coverLetter.id)
			let filteredCls = state.coverLetters.filter(cl => cl.id !== action.coverLetter.id)

			// This does not seem ideal.
			loadedCl.body = action.coverLetter.body
			loadedCl.company = action.coverLetter.company.name
			loadedCl.position = action.coverLetter.position.name
			
			return {
				...state,
				coverLetters: [...filteredCls, loadedCl],
				selectedCoverLetterId: loadedCl.id,
				coverLetterSelected: true
			}

		case "SAVE_NEW_COVER_LETTER":
			return {
				...state,
				coverLetters: [...state.coverLetters, action.coverLetter]
			}

		case "UPDATE_COVER_LETTER_ID":
			return {
				...state,
				selectedCoverLetterId: action.coverLetterId,
			}

		case "TOGGLE_COVER_LETTER_SELECTED":
			// debugger
			return {
				...state,
				coverLetterSelected: false
			}

		case "DELETE_COVER_LETTER":
			const remainingCoverLetters = state.coverLetters.filter(cl => cl.id != action.coverLetterId)
			return {
				...state,
				coverLetters: remainingCoverLetters,
				selectedCoverLetterId: "",
				coverLetterSelected: false
			}

		case "FILTER_COMPANIES":
			return {
				...state,
				companies: action.matches
			}

		case "FILTER_POSITIONS":
			return {
				...state,
				positions: action.matches
			}			

		default: 
			return state
	}
}