import React, { Component } from "react";
import { connect } from "react-redux"

import AsyncCreatableSelect from 'react-select/async-creatable'
import { API_ROOT, darkTextBackground, lightTextBackground } from "../../constants"

class MenuPositionSelector extends Component {
	filterOptions = (inputValue: string, searchOptions) => { //returns matching options
	  return searchOptions.filter(kw =>
	    kw.label.toLowerCase().includes(inputValue.toLowerCase())
	  );
	};

	promiseOptions = inputValue => //retrieves options from backend that match search string
	  new Promise(resolve => {
		  let configObj = {
		    method: "GET",
		    headers: {
		      "Content-Type": "application/json",
		      Accept: "application/json",
		      Authorization: localStorage.getItem("token")
		    }
		  }

		  let searchOptions = null		  
		  fetch(API_ROOT + `/positions-search/${inputValue}`, configObj)
		    .then(resp => resp.json())
		    .then((data) => {
		      // debugger
		      searchOptions = data.matches.map(item => {
		      	return {label: item.name, value: item.id, type: "position"}
		      })
		    }) 

	    setTimeout(() => {
	      resolve(this.filterOptions(inputValue, searchOptions));
	    }, 500);		
	});	

	render() {
    return (
	    <AsyncCreatableSelect
      	key={`my_unique_select_key__${this.props.positionId}`}
      	defaultInputValue={this.props.positionName ? this.props.positionName : null}	  	      
	      onChange={this.props.onPositionSelect}
	      placeholder="Position"
	      loadOptions={this.promiseOptions}
	    />	
  	)		
	}
}

const mapStateToProps = state => {
	return {
		positionId: state.positions.positionId,
		positionName: state.positions.positionName,
	}
}

export default connect(mapStateToProps)(MenuPositionSelector);