export default function usersReducer(state = {
	company: "",
	companyName: "",
	companyValues: []
}, action) {
	switch(action.type) {
		case "SET_COMPANY":
			const companyValuesObjectsArray = action.company.values.map(value => {
				return {label: value.name, used: false, type: "company-value"}
			})			

			let blurbArray = action.generalKeywords.map(gkw => {
				if (action.company.blurb.includes(gkw.label) 
						&& !action.company.values.map(val => val.name).includes(gkw.label)) {
					return {label: gkw.label, used: false, type: "company-culture"}
				}
			})
			blurbArray = blurbArray.filter(item => !!item)

			const companyCultureVals = companyValuesObjectsArray.concat(blurbArray)

			const companyNameObj = {name: action.company.name, used: false, type: "company-name"}

			return {
				...state,
				company: action.company,
				companyName: companyNameObj,
				companyValues: companyCultureVals
			}


		case "USE_COMPANY_VALUE":
			return {
				...state,
				companyValues: action.keywordUsageArray
			}

		
		case "USE_COMPANY_NAME":
			return {
				...state,
				companyName: action.companyNameObj
			}			



		case "RESET_COMPANY":
			return {
				...state,
				company: "",
				companyName: "",
				companyValues: []
			}

		default: 
			return state
	}
}