import { API_ROOT } from "../constants"

export const logIn = () => {
	return (dispatch) => {
    let configObj = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.getItem("token")
      }
    }
    fetch(API_ROOT + `/users/GETUSER`, configObj)
      .then(resp => resp.json())
      .then((data) => {
        const user = data

        if (!user.error) {
          dispatch({
            type: "ADD_USER",
            user
          });

          const coverLetters = data.cover_letters
          dispatch({
            type: "ADD_COVER_LETTERS",
            coverLetters
          });  
        }      
      })
	}
}

export const logOut = () => {
  return {
    type: "LOG_OUT"   
  }
}