import React, { Component } from "react";
import { connect } from "react-redux";

import { API_ROOT } from "../constants"
import "./search.scss"
import CopyIcon from "./copy-icon.svg"
import SearchIcon from "./search-icon.svg"


class Search extends Component {
	state = {
		term: "",
		searchMatches: [],
		noMatches: false,
		searchedTerm: ""
	}

	componentDidUpdate = () => {
		// this.highlightMatches()
	}

	handleKeyDown = (e) => {
		if (e.key === "Enter" && this.state.term) {
			this.searchTerm()
		} else if (e.key === "Enter" && !this.state.term) {
			this.setState({searchedTerm: ""})
		}
	}

  handleChange = (e) => {
  	let noMatches = this.state.noMatches
  	if (e.target.value === "") {
  		noMatches = false;
  	}
  	this.setState({term: e.target.value, noMatches: noMatches})
  }

	searchTerm = () => {
	  if (this.props.userId) {
		  let configObj = {
		    method: "GET",
		    headers: {
		      "Content-Type": "application/json",
		      Accept: "application/json",
		      Authorization: localStorage.getItem("token")
		    }    
		  }
		  // debugger
		  fetch(`${API_ROOT}/users/1/term_search/${this.state.term}`, configObj)
		    .then(resp => resp.json())
		    .then((data) => {
		    	this.setState({searchMatches: data.matches, noMatches: !data.matches.length, searchedTerm: this.state.term}, () => {
		    	})
		   })
		    .catch(err => alert(err.message))	
	  } else {
	    alert("sign in to search your cover letters for keywords")
	  }

	}

	showMatches = () => {
		if (this.state.searchMatches.length > 0) {
			return this.state.searchMatches.map((match, index) => {
				// const newMatch = match.replace(this.state.searchedTerm, "<span>dog</span>")
				return (
					<div 
						id={`match-${index}`}
						className="search-match-wrapper" 
						draggable
						onDragStart={this.startDrag}
					>
						<div className="search-match-phrase">{match.phrase}</div>
						<div className="search-match-name">{match.name}</div>

						<img className="copy-icon" src={CopyIcon} alt="copy-icon" width="20px" onClick={this.copyMatch}/>					
					</div>
				)

				// matchDiv.props.children[0].props.children = "kill kill kill"
			// const matchDiv = document.getElementById(`match-${index}`)
			// matchDiv.innerHTML = newMatch				
			})	

		} else if (this.state.noMatches) {
			return <div id="no-match-message">Sorry, no matches for "{this.state.searchedTerm}"</div>
		}	
	}

	highlightMatches = () => {
		if (document.getElementsByClassName("search-match-phrase").length) {
			const matchDivs = document.getElementsByClassName("search-match-phrase")
			const matchesArray = Array.from(matchDivs)
			matchesArray.map(match => {
				const matchText = match.innerText
				// const regex = /\bfar\b/;
				// const regex = new RegExp(`\bfarb\b`);
				// match.innerHTML = matchText.replace(regex, `<b>${this.state.searchedTerm}</b>`)
				match.innerHTML = matchText.replace(this.state.searchedTerm, `<b>${this.state.searchedTerm}</b>`)
			})
		}
	}

	startDrag = e => {
		console.log("dragging")
		e.dataTransfer.setData("object", JSON.stringify({text: e.target.innerText}))
	}

	copyMatch = (e) => {
	  const el = document.createElement('textarea');
	  // el.value = e.target.innerText;
	  // const match = document.getElementById("search-match-wrapper")
	  el.value = e.target.previousSibling.previousSibling.innerText; // Wow, gotta be a better identifier than that
	  document.body.appendChild(el);
	  el.select();
	  document.execCommand('copy');
	  document.body.removeChild(el);

	  // window.open(url,'_blank');
	};	

	render() {
		return (
			<div id="search-wrapper">
				<div id="search-input-wrapper">
					<input 
						id="search-input"
						autocomplete="off"
						maxlength="100"
						type="text" onChange={this.handleChange} onKeyDown={this.handleKeyDown}
						placeholder="search in cover letters" value={this.state.term}
					/>

					<img id="search-icon" onClick={this.state.term ? this.searchTerm : null} src={SearchIcon} alt="search icon" width="20px" />
				</div>

				<div className="search-match-container">
					<div id="search-guide" className="guide-text">
						{!this.state.searchedTerm || this.state.searchedTerm === "" ? "Search for keywords in any of the cover letters you have saved in Coverly" : null}
					</div>
					{this.showMatches()}
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		userId: state.users.id
	}
}

export default connect(mapStateToProps)(Search);

