import React, { useState, useEffect, Component } from 'react';
import DarkModeToggle from "react-dark-mode-toggle";

import $ from "jquery"

import { logIn, resetQuotas } from "./actions/usersActions.js"
import { connect } from "react-redux"

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import logo from './logo.svg';
import './App.css';

import { API_ROOT, darkBackdrop, lightBackdrop } from "./constants"
import Editor from "./components/Editor.js"
import Search from "./containers/Search.js"
import CultureCam from "./components/culture-cam/CultureCam.js"
import NavBar from "./containers/NavBar.js"
import Keywords from "./containers/Keywords.js"
import FileBrowser from "./containers/FileBrowser.js"
import Contact from "./components/Contact.js"

import SignIn from "./components/authentication/SignIn.js"
import Authorizer from "./components/authentication/Authorizer.js"
import CheckoutForm from "./components/payment/CheckoutForm.js"
import {CardElement} from '@stripe/react-stripe-js';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51I5aZ0DNz9cUktccP4Yr9RgNYwKWuHEIn3ep6dl4MdpkKMW923k0UFYGlwIZa7gTIYUsVr2gLUWMituMmtPtYQhq00nQwbzaoh');
  // componentDidMount = () => {
  //   this.props.logIn()
  // }

class App extends Component {
  state = {
    darkMode: true
  }

  handleOnClick = () => {
    const darkMode = this.state.darkMode
    this.setState({darkMode: !darkMode}, () => {
      document.body.style["background-color"] = this.state.darkMode ? "#345B63" : lightBackdrop
    })
  }

          // style={{
          //   "background-color": this.state.darkMode ? "#345B63" : "#dbe3e5",
          //   border: "2px solid transparent",
          //   transition: "background-color 0.5s ease"
          // }}

  render() {
    return (
      <Router>
        <div 
          className="App"
          style={{
            // "background-color": this.state.darkMode ? "#345B63" : "transparent",
            border: "2px solid transparent",
            transition: "background-color 0.5s ease",
            // "z-index": "-10"
          }}          
        >   
          {/*<NavBar />*/}
          {/*<NavBar />*/}
          <Route path="/login"><SignIn/></Route>
          <Route path="/auth"><Authorizer /></Route>
          <Route exact path="/">        
            <main>                    
              <Keywords darkMode={this.state.darkMode} />
              <Editor darkMode={this.state.darkMode} /> 
              <div id="search-culture-wrapper">
                {/*<Search /> */}
                <CultureCam darkMode={this.state.darkMode} />                   
              </div> 
            {/*<Elements stripe={stripePromise}>
              <CheckoutForm clientSecret={"clientSecret"} />
            </Elements>*/}         
            </main>
          </Route>
          {/*<Contact />*/}
        </div>


        <DarkModeToggle
          className="dark-mode-toggle"
          onChange={this.handleOnClick}
          checked={this.state.darkMode}
          size={80}
        />           
      </Router>
    );
  }
}

export default connect(null, { logIn })(App);


