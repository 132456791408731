import { API_ROOT } from "../constants"

export const saveNewCoverLetter = (coverLetter) => {
  return (dispatch) => {
    dispatch({
      type: "SAVE_NEW_COVER_LETTER",
      coverLetter
    });          
  }  
}

export const loadCoverLetter = (userId, coverId) => {
  return (dispatch) => {
    let configObj = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.getItem("token")
      }
    }
    // debugger
    fetch(API_ROOT + `/users/${userId}/cover_letters/${coverId}`, configObj)
      .then(resp => resp.json())
      .then((data) => {
        const coverLetter = data
        const position = data.position
        const company = data.company
        dispatch({
          type: "LOAD_COVER_LETTER",
          coverLetter
        }); 
        dispatch({
          type: "SET_POSITION",
          position
        })   
        dispatch({
          type: "SET_COMPANY",
          company
        })      
        // debugger
      })
	}
}

export const deleteCoverLetter = (userId, coverLetterId) => {
  return (dispatch) => {
    let configObj = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.getItem("token")
      }
    }

    fetch(API_ROOT + `/users/${userId}/cover_letters/${coverLetterId}`, configObj)
      .then(resp => resp.json())
      .then((data) => {
        if (data.status === "success") {
          dispatch({
            type: "DELETE_COVER_LETTER",
            coverLetterId
          })
          dispatch({
            type: "RESET_POSITION"
          })   
          dispatch({
            type: "RESET_COMPANY"
          })
          dispatch({
            type: "RESET_KEYWORDS"
          })                        
        }
      })
  }
}

export const getMatches = (category, searchString) => {
  return (dispatch) => {
    let configObj = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.getItem("token")
      }
    }
    // debugger
    fetch(API_ROOT + `/${category}/${searchString}`, configObj)
      .then(resp => resp.json())
      .then((data) => {
        debugger
        const matches = data.matches
        dispatch({
          type: `FILTER_${category.toUpperCase()}`,
          matches
        })   
      })
  }
}

export const updateCoverLetterId = (coverLetterId) => {
  return {
    type: "UPDATE_COVER_LETTER_ID",
    coverLetterId
  }
}


export const toggleCoverLetterSelected = () => {
  return {type: "TOGGLE_COVER_LETTER_SELECTED"};
}