export default function paymentsReducer(state = {
	clientSecret: ""
}, action) {
	switch(action.type) {
		case "DONT_DO_ANYTHING":
			return state
			//  {
			// 	...state,
			// 	positions: action.positions
			// }


		default: 
			return state
	}
}