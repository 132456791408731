import React, { Component } from "react";
import { connect } from "react-redux"
import Select from 'react-select';
import AsyncSelect from 'react-select/async-creatable'

import { API_ROOT } from "../constants"

import { loadCoverLetter } from "../actions/coverLetterActions.js"
// import "./search.scss"

const customStyles = {
  control: (provided, state) => ({
  	...provided,
  	border: "none"
  }),

  menuList: (provided, state) => ({
  	...provided,
  	// border: "2px solid blue",
  	zIndex: 9999
  }),
}

class FileBrowser extends Component {
	state = {
		coverLetterId: "",
		coverLetters: [{id: 5, name: "TEST CL"}]
	}

	componentDidMount() {
		this.loadCoverLetterNames()
	}

	handleChange = coverLetterId => {
		// debugger
		this.setState({coverLetterId: coverLetterId.value}, () => {
			console.log(this.state.coverLetterId);
			this.props.loadCoverLetter(this.props.userId, this.state.coverLetterId);
			this.props.handleFileSelect();
		})
	}

	loadCoverLetterNames = () => {
    let configObj = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.getItem("token")
      }
    }
    // debugger
    fetch(API_ROOT + `/users/${this.props.userId}/cover_letters`, configObj)
      .then(resp => resp.json())
      .then((data) => {
      	this.setState({coverLetters: data.cover_letters})
      })		
	}

	loadDefaultOptions = () => {
		debugger
	}

	renderOptions = () => {
    if (this.state.coverLetters) {
			return this.state.coverLetters.map(cl => {
				return {
					value: cl.id, 
					label: `${cl.name}`				
				}
			})
    }
	}

	render() {
		const { coverLetters } = this.props
		return (
			<div id="file-browser-wrapper" onBlur={() => this.props.handleBlur(false)} >
	      <Select
	        styles={customStyles}
	        value={this.state.coverLetterId}
	        onChange={this.handleChange}
	       	options={this.renderOptions()}
	      />					
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		coverLetters: state.coverLetters.coverLetters,
		userId: state.users.id
	}
}

export default connect(mapStateToProps, {loadCoverLetter})(FileBrowser);


