import React, { Component } from "react";
import { kColors, darkTextBackground, lightTextBackground } from "../../constants";
import { connect } from "react-redux"

// import "./contact.scss"

class PostingScoreBar extends Component {
	componentDidUpdate(prevProps) {
			setTimeout(() => {document.getElementById("content-editor-plus-csb-wrapper").style.borderBottom = `5px solid ${(this.props.postingSkills.filter(kw => kw.used).length / this.props.postingSkills.length) > 0.5 ? kColors.postingKeywordColor : this.props.darkMode ? darkTextBackground : lightTextBackground}`}, 200)
			
	}

	render() {
		const { darkMode } = this.props
		const { postingSkills } = this.props
		const scoreBarWidth = () => {
			if (postingSkills.length > 0) {
				return postingSkills.filter(kw => kw.used).length / postingSkills.length * 100
			} else {
				return 2
			}
		}

		return (
			<React.Fragment>
				<div id="score-bar-wrapper" style={{"background-color": darkMode ? darkTextBackground : lightTextBackground}}>
					<div id="score-bar-section">
						<div 
							id="score-bar" 
							style={{
								border: `2px solid ${kColors.postingKeywordColor}`,
								width: `${true ? scoreBarWidth() : 100}%`
							}}												
						>
						</div>
						<div 
							id="score-fraction"
							style={{color: kColors.postingKeywordColor}}
						>{postingSkills.length > 0 ? `${postingSkills.filter(kw => kw.used).length}/${postingSkills.length}` : "0/0"}</div>

					</div>
					<div 
						id="position-halfway-mark-container"
						style={{
							"border-color": (postingSkills.filter(kw => kw.used).length / postingSkills.length) > 0.5 ? kColors.postingKeywordColor : "lightgrey",
							opacity: (postingSkills.filter(kw => kw.used).length / postingSkills.length) > 0.5 ? 1 : 0.5,							
						}}
					>
					</div>
					<div 
						id="position-halfway-text" 
						style={{
							display: this.props.body ? "none" : "block",							
							color: (postingSkills.filter(kw => kw.used).length / postingSkills.length) > 0.5 ? kColors.postingKeywordColor : "lightgrey",
							opacity: (postingSkills.filter(kw => kw.used).length / postingSkills.length) > 0.5 ? 1 : 0.5,
						}}>50%</div>
				</div>
				{/*<div id="posting-connector-box"></div>*/}
			</React.Fragment>	
		)
	}
}

const mapStateToProps = state => {
	return {
		postingSkills: state.positions.postingSkills,
	}
}

export default connect(mapStateToProps)(PostingScoreBar);

// (postingSkills.filter(kw => kw.used).length} / postingSkills.length) > 0.5