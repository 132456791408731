export default function usersReducer(state = {
	id: "",
	firstName: "",
	generalKeywords: []
}, action) {
	switch(action.type) {
		case "ADD_USER":
			return {
				...state,
				id: action.user.id,
				firstName: action.user.first_name
			}

		case "LOG_OUT":
			return {
				...state,
				id: "",
				firstName: ""
			}

			case "ADD_GENERAL_KEYWORDS":
				const generalKeywordObjectsArray = action.generalKeywords.map(keyword => {
					return {label: keyword.name, used: false, type: "general-keyword", industry: keyword.industry_id, companies: keyword.companies}
				})
				return {
					generalKeywords: generalKeywordObjectsArray
				}


		case "USE_GENERAL_KEYWORD":
			return {
				...state,
				generalKeywords: action.keywordUsageArray
			}				

		default: 
			return state
	}
}