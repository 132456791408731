import React, { Component } from "react";
// import "./culture-cam.css"
import { connect } from "react-redux"
import { setSkills } from "../../actions/keywordsActions.js"
import { darkTextBackground, lightTextBackground, kColors } from "../../constants"

class PostingSkills extends Component {
	state = {
		matchedSkills: [],
		body: ""
	}

	componentDidMount() {
		const postingInput = document.getElementById('posting-input');
		postingInput.addEventListener('input', (el) => {
		    this.handleChange(postingInput)
		});  	

		postingInput.addEventListener("paste", function(e) {
    // cancel paste
	    e.preventDefault();

	    // get text representation of clipboard
	    var text = (e.originalEvent || e).clipboardData.getData('text/plain');

	    // insert text manually
	    document.execCommand("insertHTML", false, text);
		});
	}	

	componentDidUpdate(prevProps) {
		if (this.props.industryKeywords.length != prevProps.industryKeywords.length
				|| this.props.company.name != prevProps.company.name) {
			setTimeout(() => this.handleChange(document.getElementById('posting-input')), 250) //Timeout because otherwise race condition
			// let wrapper = document.getElementById('posting-skills-wrapper')
			// let height = wrapper.clientHeight;
			// wrapper.style.height = height
		}
	}

	handleChange = (el) => {
		// const post = e.target.value
		if (true) { //(this.props.keywords.length > 0)
			const post = el.innerText
			const postIdentifier = Math.random().toString(36).substring(7)
			
			// let cultureBlurbKeywords = []
			// if (this.props.company) {
			// 	cultureBlurbKeywords = this.props.generalKeywords.filter(kw => {
			// 		return this.props.company.blurb.includes(kw.label)
			// 	})
			// }

			// const allSkillsArray = this.props.industryKeywords.concat(cultureBlurbKeywords, this.props.companyValues) //Object.values(state.topSkills).flat()
			// const allSkillsArray = this.props.industryKeywords.concat(cultureBlurbKeywords, this.props.generalKeywords.filter(kw => kw.companies.includes(this.props.companyName.name)))
			const allSkillsArray = this.props.industryKeywords.concat(this.props.companyValues)
			const matchedSkills = []

			allSkillsArray.map(skill => {
				if (post.includes(skill.label) || post.includes(skill.label.toLowerCase())) {
					matchedSkills.push(skill.label)
				}
			})

			// this.setState({matchedSkills: matchedSkills})
			this.props.setSkills(postIdentifier, matchedSkills)
			this.setState({body: el.innerText})
		} else {
			alert("Please select a position above first")
		}
	}

	renderIndustrySkills = () => {
		const { industryKeywords, darkMode } = this.props
		let industrySkills = this.props.postingSkills.map(function(skill, index) {
			if (industryKeywords.filter(kw => kw.label == skill.label).length > 0) {
				return (
					<div 
						className={`list-skill skills-present ${skill.used ? "used-skill" : "unused-skill"}`}
					>
						<span style={{"color": kColors.industryKeywordColor }}>
							{index + 1}. 
						</span>
						<span className="posting-label"> {skill.label}</span>
					</div>
				) 
			}
		})
		industrySkills = industrySkills.filter(skill => !!skill) //NOT NULL

		if (industrySkills.length > 0) {
			return (
				<div 
					className="skills-list"
					style={{
						"color": darkMode ? "white" : "black",
					}}
				>
					{industrySkills}
				</div>		
			)
		} else {
			return <div className="empty-posting-keywords-message">select a position to view relevant industry skills</div>
		}
	}

	renderCompanySkills = () => {
		const { companyValues, darkMode } = this.props
		// if (true) {}
		let i = 0
		let companySkills = this.props.postingSkills.map(function(skill, index) {
			if (companyValues.filter(kw => kw.label == skill.label).length > 0) { //if it's NOT an industry keyword
				i = i + 1
				return (
					<div 
						className={`list-skill skills-present ${skill.used ? "used-skill" : "unused-skill"}`}
					>
						<span style={{"color": kColors.valueKeywordColor}}>
							{i}. 
						</span>
						<span className="posting-label"> {skill.label}</span>
					</div>
				) 
			} else {
				return null
			}
		})
		companySkills = companySkills.filter(skill => !!skill) //NOT NULL

		if (companySkills.length > 0) {
			return (
				<div 
					className="skills-list"
					style={{
						"color": darkMode ? "white" : "black",
					}}
				>
					{companySkills}
				</div>	
			)
		} else {
			return <div className="empty-posting-keywords-message">select a company to view relevant company values</div>
		}		
	}

	render() {
		const { darkMode, postingSkills } = this.props
		return (
			<div 
				id="posting-skills-wrapper" 
				style={{
					border: `2px solid ${kColors.postingKeywordColor}`,
					// "max-height": this.state.body ? "1000px" : "500px" //arbitrary values
				}}
			>
				<div 
					id="posting-input"
					contentEditable="true"
					data-placeholder="Paste a job description here to view keywords which highlight as you type. Select a position above for even more keyword matches."
					style={{
						overflow: "scroll",
						"background-color": darkMode ? darkTextBackground : lightTextBackground,
						"color": darkMode ? "lightgrey" : "darkgrey",
						transition: "background-color 0.5s ease"
					}}
				>
				</div>
				{/*<textarea 
					id="posting-input"
					placeholder="paste a job description here to view keywords"
					onChange={(e) => this.handleChange(e, this.state)}
				/>*/}
				<div id="posting-keywords-container" style={{display: this.state.body ? "block" : "none"}}>
					<div className="posting-keywords-header" 
						style={{color: darkMode ? "white" : "black"}}>
							industry matches
					</div>
					<div className="skills-list-container">
						{this.renderIndustrySkills()}		
					</div>	


					<div className="posting-keywords-header" 
						style={{color: darkMode ? "white" : "black"}}>
							cultural matches
					</div>
					<div className="skills-list-container">
						{this.renderCompanySkills()}			
					</div>	
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		postingSkills: state.positions.postingSkills,
		keywords: state.positions.positionKeywords,
		industryKeywords: state.positions.industryKeywords,
		generalKeywords: state.users.generalKeywords,
		companyValues: state.companies.companyValues,
		companyName: state.companies.companyName,
		company: state.companies.company
	}
}

export default connect(mapStateToProps, { setSkills })(PostingSkills)


