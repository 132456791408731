import React, { Component } from "react";
import "./culture-cam.scss"
import { connect } from "react-redux"

import MenuCompanySelector from "../select/MenuCompanySelector.js"
import ColorLegend from "../../components/ColorLegend.js"

import { setCompany } from "../../actions/keywordsActions.js"
import { darkTextBackground, lightTextBackground, kColors } from "../../constants"

class CultureCam extends Component {
	state = {
		company: ""
	}

	handleCompanySelect = company => {
		this.props.setCompany(company.value, company.label, this.props.generalKeywords)
		this.setState({company: company.label})
    setTimeout(() => {
	    if (company.value == "request-company") {
	    	alert("Thanks, check back tomorrow!")
	    }
    }, 250)
	}	

	renderCompanyBlurb = () => {
		setTimeout(() => {
			const { generalKeywords, companyValues } = this.props
			let formattedCompanyBlurb = this.props.company.blurb
			let blurbDiv = document.getElementById('blurb-content')
			blurbDiv.innerHTML = formattedCompanyBlurb
			const filteredVals = companyValues.filter(val => val.type == "company-value")
			const valLabels = filteredVals.map(val => val.label)
			debugger
			generalKeywords.map(gkw => {
				if (formattedCompanyBlurb.includes(gkw.label) && !valLabels.includes(gkw.label)) {
					// debugger
					blurbDiv.innerHTML = blurbDiv.innerHTML.replace(gkw.label, `<span class="blurb-keyword" style="text-decoration: ${gkw.used ? "none" : "underline"}; ${gkw.used ? "color" : "text-decoration-color"} : ${kColors.valueKeywordColor}">${gkw.label}</span>`)
				}
			})
			blurbDiv.innerHTML.replace(/(\r\n|\n|\r)/gm, "")
		}, 100) //NECESSARY. SOME SORT OF RACE CONDITION

	}

	renderCompanyValues = () => {
		return this.props.companyValues.filter(val => val.type == "company-value").map((value, index) => {
			return (
				<div 
					className={`list-value value-present ${value.used ? "used-value" : "unused-value"}`}
				>
					{index + 1}. {value.label}
				</div>
			) 
		})
	}

	render() {
		const { company } = this.props
		return (
			<div id="culture-and-legend-wrapper">
				<div id="culture-wrapper">
		      <div id="company-select-wrapper" className="tag-select-wrapper">
			      <MenuCompanySelector id="company-selector" darkMode={this.props.darkMode} onCompanySelect={this.handleCompanySelect}/>	
		      </div>				
					{/*<div id="culture-company">{company.name}</div>*/}
					<div id="blurb-and-values" style={{border: `2px solid ${kColors.valueKeywordColor}`}}>
						<div 
							id="culture-blurb" 
							style={
								{
									"background-color": this.props.darkMode ? darkTextBackground : lightTextBackground,
									"color": this.props.darkMode ? "white" : "black",
								}
							}
						>
							<div id="blurb-content">{company ? this.renderCompanyBlurb() : <span id="blurb-placeholder" style={{"color": "#B2B1B9", "font-style": "italic", "font-size": "1.2em",}}>Pick a company for culture insights!</span>}</div>
							{company ? <div id="culture-link"><a href={company.link} target="_blank">learn more</a></div> : null}
						</div>
						
						<div 
							id="company-values-list-wrapper"
							style={{
								"color": this.props.darkMode ? "white" : "black",		
								"padding-bottom": company ? "5px" : "0px"				
							}}
						>
							{company ? <div id="value-list-header">cultural values</div> : null}
							<div id="company-values-list">
								{this.renderCompanyValues()}
							</div>
						</div>
					</div>	
					</div>
	      {<ColorLegend darkMode={this.props.darkMode}/>}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		company: state.companies.company,
		companyValues: state.companies.companyValues,
		generalKeywords: state.users.generalKeywords
	}
}

// export default connect(mapStateToProps)(CultureCam)
export default connect(mapStateToProps, { setCompany })(CultureCam);



