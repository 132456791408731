import React from 'react';
import {ElementsConsumer, CardElement} from '@stripe/react-stripe-js';
import { API_ROOT } from "../../constants"

import CardSection from './CardSection';

class CheckoutForm extends React.Component {
  state = {
  	clientSecret: ""
  }

  getClientSecret = () => {
    let configObj = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.getItem("token")
      }
    }

    // debugger
    fetch(API_ROOT + `/subscriptions/create-checkout-session`, configObj)
      .then(resp => resp.json())
      .then((responseJson) => {
        // debugger
        this.setState({clientSecret: responseJson.client_secret});
        // dispatch({
        //   type: "SAVE_PAYMENT_INTENT_SECRET",
        //   clientSecret
        // });       
      })
  }  

  handleSubmit = async (event, props) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    const {stripe, elements} = this.props

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // debugger
    const result = await stripe.confirmCardPayment(`${this.state.clientSecret}`, {
      payment_method: {
        card: elements.getElement("card"),
        billing_details: {
          name: 'Jenny Rosen',
        },
      }
    });

    if (result.error) {
      debugger
      // Show error to your customer (e.g., insufficient funds)
      console.log(result.error.message);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
        console.log("WATUP")
      }
    }
  };

	createCustomer = () => {
	  let billingEmail = document.querySelector('#email').value;
	  // debugger
	  return fetch(API_ROOT + '/subscriptions/create-customer', {
	    method: 'post',
	    headers: {
	      'Content-Type': 'application/json',
	    },
	    body: JSON.stringify({
	      email: billingEmail,
	    }),
	  })
	    .then((response) => {
	      return response.json();
	    })
	    .then((result) => {
	      // result.customer.id is used to map back to the customer object
	      debugger
	      return result;
	    });
	}

	createPaymentMethod(props) {
	  const {stripe, elements} = props
	  let card = elements.getElement("card")
	  debugger
	  
	  // const customerId = {{ CUSTOMER_ID }};
	  const customerId = "cus_IjH78MZt4DXTId";
	  
	  // Set up payment method for recurring usage
	  
	  // let billingEmail = document.querySelector('#email').value;
	  let billingEmail = "sally@sally@sally.az";

	  // let priceId = document.getElementById('priceId').innerHTML.toUpperCase();
	  let priceId = "price_1I5avADNz9cUktccE0WvL6G1";

	  stripe
	    .createPaymentMethod({
	      type: 'card',
	      card: card,
	      billing_details: {
	        name: billingEmail,
	      },
	    })
	    .then((result) => {
	      if (result.error) {
	        debugger
	        // displayError(result);
	        console.log(result.error)
	      } else {
	        this.createSubscription({
	          customerId: customerId,
	          paymentMethodId: result.paymentMethod.id,
	          priceId: priceId,
	        });
	        // debugger
	      }
	    });
	}


	createSubscription = ({ customerId, paymentMethodId, priceId }) => {
	  return (
	    fetch(API_ROOT + '/subscriptions/create-subscription', {
	      method: 'post',
	      headers: {
	        'Content-type': 'application/json',
	      },
	      body: JSON.stringify({
	        customerId: customerId,
	        paymentMethodId: paymentMethodId,
	        priceId: priceId,
	      }),
	    })
	      .then((response) => {
	        return response.json();
	      })
	      // If the card is declined, display an error to the user.
	      .then((result) => {
	        if (result.error) {
	          // The card had an error when trying to attach it to a customer.
	          throw result;
	        }
	        debugger
	        return result;
	      })
	      // Normalize the result to contain the object returned by Stripe.
	      // Add the additional details we need.
	      .then((result) => {
	        return {
	          paymentMethodId: paymentMethodId,
	          priceId: priceId,
	          subscription: result,
	        };
	      })
	      // Some payment methods require a customer to be on session
	      // to complete the payment process. Check the status of the
	      // payment intent to handle these actions.


	      // .then(handlePaymentThatRequiresCustomerAction)
	      // // If attaching this card to a Customer object succeeds,
	      // // but attempts to charge the customer fail, you
	      // // get a requires_payment_method error.
	      // .then(handleRequiresPaymentMethod)
	      // // No more actions required. Provision your service for the user.
	      // .then(onSubscriptionComplete)
	      // .catch((error) => {
	      //   // An error has happened. Display the failure to the user here.
	      //   // We utilize the HTML element we created.
	      //   showCardError(error);
	      // })
	  );
	}

  render() {
    return (
	    <div>
		    <div>Client Secret: {this.state.clientSecret}</div>
		    <button onClick={this.getClientSecret}>GET CLIENT SECRET</button>          
	      <form onSubmit={this.handleSubmit}>
	        <CardSection />
	        <button disabled={!this.props.stripe}>Confirm order</button>
	      </form>
	      <input type="email" placeholder="sally@sally.az" id="email" />
	      <div>4242424242424242</div>
	      <button onClick={this.createCustomer}>Create Customer</button>	    	
	      <button onClick={() => this.createPaymentMethod(this.props)}>Create Payment Method</button>	    	
	    </div>
    );
  }
}

export default function InjectedCheckoutForm() {
  return (
    <ElementsConsumer>
      {({stripe, elements}) => (
        <CheckoutForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}