import { API_ROOT } from "../constants"

export const getGeneralKeywords = () => {
  return (dispatch) => {
    let configObj = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.getItem("token")
      }
    }

    fetch(API_ROOT + `/keywords/general`, configObj)
      .then(resp => resp.json())
      .then((generalKeywords) => {
        // const positions = data
        // debugger
        dispatch({
          type: `ADD_GENERAL_KEYWORDS`,
          generalKeywords
        })   
      })        
  }  
}

export const getPositions = () => {
  return (dispatch) => {
    let configObj = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.getItem("token")
      }
    }

    fetch(API_ROOT + `/positions`, configObj)
      .then(resp => resp.json())
      .then((data) => {
        const positions = data
        // debugger
        dispatch({
          type: `ADD_POSITIONS`,
          positions
        })   
      })        
  }  
}

export const setKeywordsPosition = (positionKey, positionName, setEditorPosition) => {
  return (dispatch) => {
    
    let fixedName = positionName
    if (positionKey == "request-position") {
      let nameArr = positionName.split(" ")
      nameArr.shift()
      fixedName = nameArr[0].replace(/['"]+/g, '')      
    }    

    let configObj = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.getItem("token")
      },
      body: JSON.stringify({
        id: positionKey,
        name: fixedName
      })         
    }
    fetch(API_ROOT + `/positions`, configObj)
      .then(resp => resp.json())
      .then((position) => {

        if (position) {
          dispatch({
            type: `SET_KEYWORDS_POSITION`,
            position
          })   
          
          if (setEditorPosition) {
            dispatch({
              type: `SET_EDITOR_POSITION`,
              position
            })  
          } 
        }
      })  
  }
}

export const setKeywords = (keywords) => {
  return ({type: "SET_KEYWORDS", keywords})
}

export const setCompany = (companyId, companyName, generalKeywords) => {
  return (dispatch) => {
    // Fixing Company Name
    let fixedName = companyName
    if (companyId == "request-company") {
      let nameArr = companyName.split(" ")
      nameArr.shift()
      fixedName = nameArr[0].replace(/['"]+/g, '')      
    }

    let configObj = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.getItem("token")
      },
      body: JSON.stringify({
        id: companyId,
        name: fixedName
      })         
    }

    fetch(API_ROOT + `/companies`, configObj)
      .then(resp => resp.json())
      .then((data) => {      
        const company = data
        dispatch({
          type: `SET_COMPANY`,
          company,
          generalKeywords
        })   
      }) 
  }     
}

export const useKeyword = (keywordUsageArray) => {
  return ({type: "USE_KEYWORD", keywordUsageArray})
}

export const useGeneralKeyword = (keywordUsageArray) => {
  return ({type: "USE_GENERAL_KEYWORD", keywordUsageArray})
}

export const useCompanyValue = (keywordUsageArray) => {
  return ({type: "USE_COMPANY_VALUE", keywordUsageArray})
}

export const useCompanyName = (companyNameObj) => {
  return ({type: "USE_COMPANY_NAME", companyNameObj})
}

export const useIndustryKeyword = (keywordUsageArray) => {
  return ({type: "USE_INDUSTRY_KEYWORD", keywordUsageArray})
}

export const setSkills = (postIdentifier, matchedSkillsArray) => {
  const postingInfo = {postingIdentifier: postIdentifier, matchedSkillsArray: matchedSkillsArray}
  return ({type: "SET_SKILLS", postingInfo})
}

export const useSkills = (skillsUsageArray) => {
  return ({type: "USE_SKILLS", skillsUsageArray})
}

// export const getFilteredCompanies = inputValue => {
//   return (dispatch) => {
//     let configObj = {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         Authorization: localStorage.getItem("token")
//       }
//     }

//     fetch(API_ROOT + `/companies/${inputValue}`, configObj)
//       .then(resp => resp.json())
//       .then((data) => {
//         const positions = data
//         debugger
//         dispatch({
//           type: `ADD_POSITIONS`,
//           positions
//         })   
//       })        
//   }    
// }















