import React, { Component } from 'react';
import { logIn } from "../../actions/usersActions.js"

import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";

// import { Redirect } from "react-router-dom";

import { API_ROOT, FRONTEND_DOMAIN } from "../../constants"
import "./auth.css"

class Authorizer extends Component {
	componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('token')
    localStorage.setItem("token", token)
    // window.open("https://coverly.io", "_self")
    window.open(FRONTEND_DOMAIN, "_self")
    // this.props.logIn() OR REDIRECT AGAIN? NOW THAT TOKEN HAS BEEN SET?
    
    // let configObj = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json"
    //   },
    //   body: JSON.stringify({
    //     code: randomCode
    //   })
    // }

    // fetch(API_ROOT + "/authenticate", configObj)
    //   .then(resp => resp.json())
    //   .then(data => {
    //     localStorage.setItem("token", data.auth_token)
    //     this.props.logIn()
    //   })
    //   .catch(err => alert(err.message))  
	}

  hop = () => {
    debugger
  }

	render() {
		// debugger
		return (
			<div onClick={this.hop}>HOP</div>			
		)
	}
}


export default connect(null, {  logIn })(Authorizer);







