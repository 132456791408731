export default function positionsReducer(state = {
	positions: [],
	selectedKeywordsPosition: "",
	keywordsPositionId: "",
	keywordsPositionName: "",
	keywordsPositionIndustry: "",
	positionKeywords: [],
	industryKeywords: [],
	editorPositionId: "",
	editorPositionName: "",	
	postingSkills: [],
	newPostingSkills: [],
	postingIdentifier: ""
}, action) {
	switch(action.type) {
		case "ADD_POSITIONS":
			return {
				...state,
				positions: action.positions
			}

		case "SET_KEYWORDS_POSITION":
			debugger
			const keywordObjectsArray = action.position.keywords.map(keyword => {
				return {label: keyword.name, used: false, type: "position-keyword", industry: keyword.industry}
			})

			const industryKeywordObjectsArray = action.position.industry_keywords.map(keyword => {
				return {label: keyword.name, used: false, type: "industry-keyword", industry: keyword.industry}
			})
			debugger
			return {
				...state,
				keywordsPositionId: action.position.id,
				keywordsPositionName: action.position.name,
				keywordsPositionIndustry: action.position.industry,
				positionKeywords: keywordObjectsArray,
				industryKeywords: industryKeywordObjectsArray
			}


		case "SET_EDITOR_POSITION":
			return {
				...state,
				editorPositionId: action.position.id,
				editorPositionName: action.position.name,
			}

		
		case "RESET_POSITION":
			return {
				...state,
				keywordsPositionId: "",
				keywordsPositionName: "",
				editorPositionId: "",
				editorPositionName: "",
				positionKeywords: []
			}

		case "USE_KEYWORD":
			return {
				...state,
				positionKeywords: action.keywordUsageArray
			}


		case "USE_INDUSTRY_KEYWORD":
			return {
				...state,
				industryKeywords: action.keywordUsageArray
			}


		case "SET_SKILLS":
			const skillObjectsArray = action.postingInfo.matchedSkillsArray.map(skill => {
				return {label: skill, used: false, type: "posting-skill"}
			})

			return {
				...state,
				postingSkills: skillObjectsArray,
				postingIdentifier: action.postingInfo.postingIdentifier
			}

		case "USE_SKILLS":
			return {
				...state,
				postingSkills: action.skillsUsageArray
			}

		default: 
			return state
	}
}