import React, { Component } from "react";
import { kColors, darkTextBackground, lightTextBackground } from "../constants"
import { connect } from "react-redux"

class ColorLegend extends Component {
	render() {
		const { postingSkills, generalKeywords, positionKeywords, industryKeywords, companyValues, companyName, darkMode } = this.props
		
		let postingSkillsFraction = postingSkills.filter(kw => kw.used).length / postingSkills.length
		let companyValuesFraction = companyValues.filter(kw => kw.used).length / companyValues.length
		let postingValueAverage = (postingSkillsFraction + companyValuesFraction) / 2
		
		let usedGeneralKeywordsCount = generalKeywords.filter(kw => kw.used).length
		let usedIndustryKeywordsCount = industryKeywords.filter(kw => kw.used).length
		let usedPositionKeywordsCount = positionKeywords.filter(kw => kw.used).length
		let totalKeywordCount = usedGeneralKeywordsCount + usedIndustryKeywordsCount + usedPositionKeywordsCount
		
		const calculateResult = () => {
			if (postingSkillsFraction > 0.5 && companyValuesFraction > 0.5 && companyName.used) {
				return "tailored"
			} else if ((usedGeneralKeywordsCount >= 5 && usedIndustryKeywordsCount >= 2 && usedPositionKeywordsCount >= 1) || totalKeywordCount >= 10) {
				return "general"
			} else {
				return "weak"
			}
		}

		const calculateSuggestions = () => {
			if (postingValueAverage > 0.5 && companyName.used) { //if the doc is tailored
				return <span style={{color: kColors.valueKeywordColor}}>Your cover letter is well tailored for this position!</span>
			} else if ((usedGeneralKeywordsCount >= 5 && usedIndustryKeywordsCount >= 2 && usedPositionKeywordsCount >= 1) || totalKeywordCount >= 10) { //if the doc is general
				return <span>Add more keywords from the <span style={{color: kColors.postingKeywordColor}}>position you pasted</span> and/or the <span style={{color: kColors.valueKeywordColor}}>company's values</span>{!companyName.used ? <span style={{color: kColors.companyKeywordColor}}> and name</span> : ""} for a more tailored letter.</span>
			} else { //if the doc is weak
				return <span>Add more keywords from the <span style={{color: kColors.postingKeywordColor}}>position you pasted</span> and/or the <span style={{color: kColors.valueKeywordColor}}>company's values</span>{!companyName.used ? <span style={{color: kColors.companyKeywordColor}}> and name</span> : ""} for a more tailored letter. Or, use more <span style={{color: kColors.generalKeywordColor}}>proactive</span> and <span style={{color: kColors.industryKeywordColor}}>industry</span>/<span style={{color: kColors.positionKeywordColor}}>position</span>-specific language for a more generalized but stronger cover-letter.</span>
			}
		}

		return (
			<div 
				id="color-legend-wrapper" 
				style={{color: darkMode ? "white" : "black", "background-color": darkMode ? darkTextBackground : lightTextBackground}}
			>
				<div id="legend-header">keyword scorecard</div>
				<div id="color-item-box">
					<div id="general-color-item-box">
						<div className="color-item-sub-box">
							<div className="color-legend-line" style={{color: kColors.generalKeywordColor}}>proactive: {`${generalKeywords.filter(kw => kw.used).length}`}</div>
							<div className="color-legend-line" style={{color: kColors.industryKeywordColor}}>industry: {`${industryKeywords.filter(kw => kw.used).length}`}</div>
							<div className="color-legend-line" style={{color: kColors.positionKeywordColor}}>position: {`${positionKeywords.filter(kw => kw.used).length}/${positionKeywords.length}`}</div>
						</div>
					</div>
					<div id="color-item-box-divider"></div>
					<div id="tailored-color-item-box">
						<div className="color-item-sub-box">
							<div className="color-legend-line" style={{color: kColors.postingKeywordColor}}>job posting: {`${postingSkills.filter(kw => kw.used).length}/${postingSkills.length}`}</div>
							<div className="color-legend-line" style={{color: kColors.valueKeywordColor}}>co. culture: {`${companyValues.filter(kw => kw.used).length}/${companyValues.length}`}</div>
							<div className="color-legend-line" style={{color: kColors.companyKeywordColor}}>co. name: {companyName.used ? <span style={{"font-size": "0.5em"}}>✅</span> : <span style={{"font-size": "0.5em"}}>❌</span>}</div>
						</div>
					</div>
				</div>
				<div id="score-result-container">
					<div id="score-results-wrapper">
						<div class="score-result" style={{color: calculateResult() == "general" ? kColors.industryKeywordColor : "lightgrey", opacity: calculateResult() == "general" ? 1 : 0.5}}>general</div>
						<div class="score-result" style={{color: calculateResult() == "weak" ? "#FF2442" : "lightgrey", opacity: calculateResult() == "weak" ? 1 : 0.5}}>weak</div>
						<div class="score-result" style={{color: calculateResult() == "tailored" ? kColors.postingKeywordColor : "lightgrey", opacity: calculateResult() == "tailored" ? 1 : 0.5}}>tailored</div>
					</div>
					<div id="score-result-suggestions">
						{calculateSuggestions()}
					</div>
				</div>
			</div>			
		)
	}
}

const mapStateToProps = state => {
	return {
		postingSkills: state.positions.postingSkills,
		generalKeywords: state.users.generalKeywords,
		positionKeywords: state.positions.positionKeywords,
		industryKeywords: state.positions.industryKeywords,		
		companyValues: state.companies.companyValues,
		companyName: state.companies.companyName
	}
}

export default connect(mapStateToProps)(ColorLegend);



	// 	.highlighted-position-keyword {
	// 		color: #00AF91;
	// 		// font-weight: bold;
	// 		// text-decoration: underline;
	// 		// text-decoration-thickness: 20px;
	// 		// border-bottom: 2px solid green;
	// 	}

	// 	.highlighted-skill {
	// 		color: #FFCC29; //gold
	// 		// font-weight: bold;
	// 		// text-decoration: underline;
	// 		// text-decoration-thickness: 20px;
	// 		// border-bottom: 2px solid gold;
	// 	}		

	// 	.highlighted-industry-keyword {
	// 		color: #2F89FC; //blue
	// 		// border-bottom: 2px solid blue;
	// 	}				

	// 	.highlighted-general-keyword {
	// 		color: #F037A5; //purple
	// 	}		

	// 	.highlighted-value {
	// 		color: #FA86BE; //pink
	// 	}

	// 	.highlighted-company-name {
	// 		color: #F98404; //orange
	// 	}
	// }	
