import React, { Component } from "react";
import { connect } from "react-redux"

import AsyncCreatableSelect from 'react-select/async-creatable'
import AsyncSelect from 'react-select/async'
import { API_ROOT } from "../../constants"

const defaultOptions = [
			{ value: "apppple", label: "AppleT" }, 
			{ value: "google", label: "Google" }
		]

class MenuCompanySelector extends Component {
	filterOptions = (inputValue: string, searchOptions) => { //returns matching options
	  return searchOptions.filter(kw =>
	    kw.label.toLowerCase().includes(inputValue.toLowerCase())
	  );
	};

	promiseOptions = inputValue => //retrieves options from backend that match search string
	  new Promise(resolve => {
		  let configObj = {
		    method: "GET",
		    headers: {
		      "Content-Type": "application/json",
		      Accept: "application/json",
		      Authorization: localStorage.getItem("token")
		    }
		  }

		  let searchOptions = null		  


		  let previousValue = inputValue

  		setTimeout(() => {
				const currentInputVal = document.getElementById("company-selector").children[1].children[0].children[0].children[0].children[0].value
				if (currentInputVal === previousValue) {
				  console.log("FETCHING")
				  fetch(API_ROOT + `/companies-search/${inputValue}`, configObj)
				    .then(resp => resp.json())
				    .then((data) => {
				      // debugger
				      if (data.matches.length > 0) {
					      searchOptions = data.matches.map(item => {
					      	return {label: item.name, value: item.id, type: "company"}
					      })				      	
				      } else {
								searchOptions = [{label: `Request "${inputValue}"`, value: "request-company", type: "company"}]
				      }
				    }) 

			    setTimeout(() => {
			      resolve(this.filterOptions(inputValue, searchOptions));
			    }, 1000);		
				}
  		}, 100)
		  

	});	

	render() {
    return (
	    <AsyncSelect
      	id={this.props.id}
      	key={`my_unique_select_key__${this.props.company.id}`}
      	defaultInputValue={this.props.company.name ? this.props.company.name : null}	      
	      onChange={this.props.onCompanySelect}
	      placeholder="Company"
	      loadOptions={this.promiseOptions}
	    />	
  	)		
	}
}

const mapStateToProps = state => {
	return {
		company: state.companies.company
	}
}

export default connect(mapStateToProps)(MenuCompanySelector);