import React, { Component } from "react";
import AsyncCreatableSelect from 'react-select/async-creatable'
import { API_ROOT } from "../../constants"

// THIS COMPONENT IS NO LONGER IN USE, HAVING BEEN REPLACED BY MenuCompanySelector and MenuPositionSelector - 3/22/2021
class Selector extends Component {
	filterOptions = (inputValue: string, searchOptions) => { //returns matching options
	  return searchOptions.filter(kw =>
	    kw.label.toLowerCase().includes(inputValue.toLowerCase())
	  );
	};

	promiseOptions = inputValue => //retrieves options from backend that match search string
	  new Promise(resolve => {
		  let configObj = {
		    method: "GET",
		    headers: {
		      "Content-Type": "application/json",
		      Accept: "application/json",
		      Authorization: localStorage.getItem("token")
		    }
		  }

		  let searchOptions = null		  
		  fetch(API_ROOT + `/${this.props.route}-search/${inputValue}`, configObj)
		    .then(resp => resp.json())
		    .then((data) => {
		      // debugger
		      searchOptions = data.matches.map(item => {
		      	return {label: item.name, value: item.id, type: this.props.placeholder.toLowerCase()}
		      })
		    }) 

	    setTimeout(() => {
	      resolve(this.filterOptions(inputValue, searchOptions));
	    }, 1000);		
	});	

	render() {
    return (
	    <AsyncCreatableSelect
	      onChange={this.props.onItemSelect}
	      placeholder={this.props.placeholder}
	      loadOptions={this.promiseOptions}
	    />	
  	)		
	}
}

export default Selector;