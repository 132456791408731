// LOCAL
// export const API_ROOT = 'http://localhost:3000';
// export const API_WS_ROOT = 'ws://localhost:3000/cable';
// export const LINKEDIN_REDIRECT_DOMAIN = "http%3A%2F%2Flocalhost:3000%2Fauth%2Flinkedin%2Fcallback"
// export const FRONTEND_DOMAIN = 'http://localhost:3001'

// PRODUCTION
export const API_ROOT = 'https://coverly-api.herokuapp.com';
export const API_WS_ROOT = 'wss://coverly-api.herokuapp.com/cable';
export const LINKEDIN_REDIRECT_DOMAIN = "https%3A%2F%2Fcoverly-api.herokuapp.com%2Fauth%2Flinkedin%2Fcallback"
export const FRONTEND_DOMAIN = "https://coverly.io"


// COLORS
export const darkTextBackground = "#152D35"//"slategrey"
export const lightTextBackground = "snow"

export const darkBackdrop = "#345B63"//"slategrey"
export const lightBackdrop = "#dbe3e5"

export const kColors = {
	generalKeywordColor: "#F037A5", //purple
	industryKeywordColor: "#2F89FC", //blue
	positionKeywordColor: "#00AF91", //green
	postingKeywordColor: "#FFCC29", //gold
	valueKeywordColor: "#FA86BE", //pink
	companyKeywordColor: "#F98404" //orange
}


export const HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
	Authorization: localStorage.getItem("token")
};