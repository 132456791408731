import React, { Component } from "react";
import "./contact.scss"

class Contact extends Component {
	state = {
		open: true
	}

	render() {
		return (
			<div id="contact-wrapper" style={{display: this.state.open ? "block" : "none"}}>
				<div id="contact-close" onClick={() => this.setState({open: false})}>✕</div>
				<div id="contact-note">Questions, comments or requests? Please email <a href="mailto: leon@coverly.io">leon@coverly.io</a></div>	
			</div>			
		)
	}
}

export default Contact